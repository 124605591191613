<template>
  <div>
    <v-row v-if="campoCalculoSelecionado.campo !== '' || campoEdit">
      <v-col cols="12">
        <v-chip
          class="d-inline-flex shrink ma-1"
          color="grey lighten-3"
          close
          @click:close="resetaCampoCalculoSelecionado"
        >
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="black"
                class="px-2 ml-1"
                min-width="0"
                small
                text
                v-bind="attrs"
                v-on="on"
              >
                <v-icon left>mdi-menu-down</v-icon>
                {{ campoCalculoSelecionado.funcao.toUpperCase() }} |
                {{ campoCalculoSelecionado.campo.toUpperCase() }}
              </v-btn>
            </template>
            <v-list dense>
              <v-list-item
                v-for="funcao in funcoes"
                :key="funcao"
                @click="() => setFuncaoCampoCalculoSelecionado(funcao)"
              >
                <v-list-item-title>
                  {{ funcao }}
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-chip>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-text-field
          ref="search"
          class="ml-n2"
          v-model="search"
          hide-details
          prepend-icon="mdi-magnify"
          label="Pesquisar campo"
          single-line
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <div
          class="transitioncard"
          :style="{ overflow: 'hidden', 'max-height': chipContainerHeight }"
        >
          <template v-for="campo in camposFiltrados">
            <v-chip
              outlined
              class="mr-1 mt-1"
              v-if="!isCampoCalculoSelecionado(campo)"
              :key="campo"
              @click="() => setCampoCalculoSelecionado(campo)"
            >
              {{ campo | toUpperCase }}
            </v-chip>
          </template>
        </div>
        <div
          justify="center"
          align="center"
          class="mt-n3"
        >
          <v-btn
            v-if="chipContainerHeight != '100%' && numCampos > 0"
            class="mt-2 mb-n3 ml-n7"
            icon
            color="gray"
            @click="chipContainerHeight = '100%'"
          >
            <v-icon>mdi-chevron-down</v-icon>
          </v-btn>
          <v-btn
            v-else-if="numCampos > 0"
            class="mt-2 mb-n3 ml-n7"
            icon
            color="gray"
            @click="chipContainerHeight = '37px'"
          >
            <v-icon>mdi-chevron-up</v-icon>
          </v-btn>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'CampoCalculo',

  props: {
    campos: {
      type: Array,
      default: () => []
    },
    campoSelecionado: {
      type: Object
    }
  },

  data() {
    return {
      search: '',
      campoCalculoSelecionado: {
        funcao: 'SOMAR',
        campo: ''
      },
      funcoes: ['SOMAR', 'MÉDIA', 'MÍNIMO', 'MÁXIMO'],
      chipContainerHeight: '37px'
    };
  },

  mounted() {
    if (this.$route.params.id) {
      this.campoCalculoSelecionado = this.campoSelecionado;
    }
  },

  computed: {
    numCampos() {
      return this.campos.length;
    },

    campoEdit() {
      if (this.$route.params.id) {
        this.setCampoCalculoSelecionado(this.campoSelecionado.campo);
        return true;
      } else {
        return false;
      }
    },

    camposFiltrados() {
      const search = this.search.toLowerCase();
      if (!search) return this.campos;
      return this.campos.filter((campo) =>
        campo.toLowerCase().includes(search)
      );
    }
  },

  methods: {
    resetaCampoCalculoSelecionado() {
      (this.campoCalculoSelecionado = {
        funcao: 'SOMAR',
        campo: ''
      }),
        this.$emit('campoCalculoSelecionado', this.campoCalculoSelecionado);
    },

    setCampoCalculoSelecionado(campo) {
      this.campoCalculoSelecionado.campo = campo;
      this.$emit('campoCalculoSelecionado', this.campoCalculoSelecionado);
    },

    setFuncaoCampoCalculoSelecionado(funcao) {
      this.campoCalculoSelecionado.funcao = funcao;
      this.$emit('campoCalculoSelecionado', this.campoCalculoSelecionado);
    },

    isCampoCalculoSelecionado(campo) {
      return this.campoCalculoSelecionado.campo === campo;
    }
  },

  watch: {
    campos() {
      this.resetaCampoCalculoSelecionado();
    }
  }
};
</script>
